const ListUser = () => {

    return (
        <div>
            JACEK kurwa placek
        </div>
    )

}

export default ListUser;