import { useState } from "react";
import "./Navbar.css";
import MobileNav from "./MobileNav/MobileNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";

const Navbar = () => {

    const [openMenu, setOpenMenu] = useState(false);

    const toggleMenu = () => {
        setOpenMenu(!openMenu);
    };

    return (
        <>

            <MobileNav isOpen={openMenu} toggleMenu={toggleMenu} /> 
            <nav className="nav-wrapper">
                <div className="nav-content">
                    <div className="moja">
                         <img className="logo" src="./assets/images/logo.png" alt="logo.pictures" />
                        <div>Memorias</div>
                    </div>
                        <ul>
                            <li>
                                <a href="#main-page-link" className="menu-item" >Strona Główna</a>
                            </li>                         
                            <li>
                                <a href="#przykladowe-wspomnienia-link" className="menu-item">Przykładowe wspomnienia</a>
                            </li>                            
                            <li>
                                <a href="#contact-us-link" className="menu-item">Skontaktuj się z nami</a>
                            </li>
                            <button className="contact-btn" onClick={() => {}}>
                                Stwórz wspomnienie
                            </button>
                        </ul>

                        <button className="menu-btn" onClick={toggleMenu}> 
                            <FontAwesomeIcon className="heniek"icon={openMenu ? faTimes : faBars } />
                        </button>
                </div>
            </nav>
        </>
    )
}

export default Navbar;