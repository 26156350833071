import "./MemoryExample.css";

const MemoryExample = ( { title, imageUrl, body } ) => {

    return (
        <div className="memory-container">

            <div className="card-title">
                <h3>{title}</h3>
            </div>
            <div className="image-container">
                <img src={imageUrl} alt="Could not find" />
            </div>

            <div className="card-body">
                <p>{body}</p>
            </div>

        </div>
    )
}

export default MemoryExample;