import Slider from "react-slick";
import { WORK_EXPERIENCE } from "../../utils/data.js";
import { EXAMPLE_MEMORIES } from "../../utils/data.js";
import ExperienceCard from "./ExperienceCard/ExperienceCard.js"
import "./WorkExperience.css";
import { useRef } from "react";

import MemoryExample from "./ExperienceCard/MemoryExample.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons'


const WorkExperience = () => {

    const sliderRef = useRef();

    const settings = {
        dots: false,
        isFinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1, 
        arrows: false,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScrollL: 1
                },
            },
        ],
    };

    const slideRight = () => {
        sliderRef.current.slickNext();
    }

    const slideLeft = () => {
        sliderRef.current.slickPrev();
    }

    return (
        <section className="experience-container" id="przykladowe-wspomnienia-link">
            <h5>Przykładowe wspomnienia</h5>
            <div className="experience-content">
                <div className="arrow-right" onClick={slideRight}>
                    <FontAwesomeIcon icon={ faChevronRight } />
                </div>
                <div className="arrow-left" onClick={slideLeft}>
                <FontAwesomeIcon icon={ faChevronLeft } />
                </div>
                <Slider ref={sliderRef} {...settings}>
                    { EXAMPLE_MEMORIES.map((item) => {
                    /// return <ExperienceCard key={item.title} details={item} />
                    return <MemoryExample
                        key={item.title} 
                        title={item.title} 
                        imageUrl={item.imageUrl}
                        body={item.body}
                        />
                    })}
                </Slider>
            </div>
        </section>
    )
}

export default WorkExperience;