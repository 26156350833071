import './MemoryPage.css'
import ImageSlider from "./ImageSlider";

const MemoryPage = ({ imie, nazwisko, opis, img1, img2, img3}) => {

    console.log(img1)

    const images = [
        img1,
        img2,
        img3
    ];

    
    return (

        <div className="skills-info-card">
            <h6>{imie} {nazwisko}</h6>
            <ImageSlider images={images} />
            <p>{opis}</p>
        </div>
    );
};

export default MemoryPage;