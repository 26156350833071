import "./Hero.css"

const Hero = () => {
    return (
        <section className="hero-container" id="main-page-link">
            <div className="hero-content">
                <h2>Twórz portrety bliskich, dzieląc się ich życiowymi opisami.</h2>
                <p>
                Platforma pielęgnująca i utrwalająca pamięć bliskich - Twoje miejsca do uhonorowania ważnych wspomnień.
                </p>
            </div>

            <div className="hero-img">
                <div>
                    <div className="tech-icon">
                        <img src="" alt="" />
                    </div>
                    <img  src="./assets/images/face01.jpg" alt="" />
                </div>

                <div>
                    <div className="tech-icon">
                        <img src="" alt=""/>
                    </div>
                    <div className="tech-icon">
                        <img src="" alt=""/>
                    </div>
                    <div className="tech-icon">
                        <img src="" alt=""/>
                    </div>
                </div>


            </div>
        </section>
    )
}

export default Hero;