import "./Footer.css"

const Footer = () => {
    return (
        <div className="footer">
           @2023 Jacek Studa, Memorias.pl
        </div>
    )
}

export default Footer;