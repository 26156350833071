import Hero from "./components/Hero/Hero";
import Navbar from "./components/Navbar";
import Skills from "./components/Skills";
import "./App.css";
import WorkExperience from "./components/WorkExperience/Workexperience";
import ContactMe from "./components/ContactMe/ContactMe";
import Footer from "./components/Footer/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MemoryPage from "./components/MemoryPage/MemoryPage";
import MainPage from "./components/MainPage";
import ListUser from "./components/ListUser";

function App() {
  return (
    <>
      <div className="container">
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/memory" element={<MemoryPage />} />
            <Route path="/user" element={<ListUser />} />
            <Route path="/:id" element={<MainPage />} /> {/* Dynamic route to capture id */}
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;