export const SKILLS = [

    {
        title:"Frontend",
        icon: "../../assets/ico1.png",
        skills : [
            {skill: "HTML5", percentage: "80%"},
            {skill: "CSS3 (Saas/SCSS)", percentage: "95%"},
            {skill: "Javascript", percentage: "75%"},
            {skill: "React.js", percentage: "80%"}
        ]
    },
    {
        title:"Backend",
        icon: "../../assets/ico2.png",
        skills : [
            {skill: "Node.js", percentage: "70%"},
            {skill: "Express.js", percentage: "65%"},
        ]
    },
    {
        title:"Tools",
        icon: "../../assets/ico3.png",
        skills : [
            {skill: "Git & Githb", percentage: "85%"},
            {skill: "Visual Stuido Code", percentage: "75%"},
            {skill: "Webpack", percentage: "60%"},
            {skill: "Responsive Design", percentage: "70%"}
        ]
    },
    {
        title:"Soft Skills",
        icon: "../../assets/ico2.png",
        skills : [
            {skill: "Problem-solving", percentage: "80%"},
            {skill: "Collaboration", percentage: "85%"},
            {skill: "Attention to Detail", percentage: "78%"}
        ]
    },

]

export const WORK_EXPERIENCE = [
    {
        title:"Senior Fronted Developer at Tech Innovators",
        date: "January 2022 - Present",
        responsibilities: [
            "Lead a team of frontend developers in the redesign of the company's flagship product",
            "Implement advanced CSS animations and transitions to enhance the overall user experience"
        ]
    },
    {
        title:"Junior Web developer at Digital Solutionns",
        date: "June 20 - December 2021",
        responsibilities: [
            "Developed and maintained client websites, ensuring high-quality standrads and timely project delivery",
            "Implemented SEO best practices, leading to a 25% increase in organic search traffic"
        ]
    },
    {
        title:"Frontend Intern at Web Studio",
        date: "May 2019 - August 2019",
        responsibilities: [
            "Assisted in the development of a new e-commerce platfrom, contributing to the implementation of frontend",
            "condiucted performance optimization tasks"
        ]
    }

]

export const EXAMPLE_MEMORIES = [
    {
        title:"Babcia Magda",
        imageUrl: "../../assets/images/face3.jpg",
        body:"Babcia Magda, niezapomniana opoka naszej rodziny, była nie tylko strażnikiem naszych wartości, ale także źródłem nieskończonej mądrości i humoru. Jej życiowe zasady moralne, które zawsze głosiła, pozostaną dla nas nieśmiertelne, kształtując nasze codzienne wybory. Niezapomniane są także jej opowieści o czasach wojny, kiedy to pomimo trudności potrafiła znaleźć powód do śmiechu, zawsze umiejąc dostrzec jasne strony nawet w najciemniejszych chwilach. Babcia Magda pozostawiła nam dziedzictwo nie tylko w formie wartości moralnych, ale także w śmiechu, który zawsze będzie rozbrzmiewał w naszych sercach, przypominając nam o jej wyjątkowym podejściu do życia."
    },
    {
        title:"Henryk Makowiecki",
        imageUrl: "../../assets/images/face01.jpg",
        body:"Dziadek Henryk, nieustraszony bohater naszej rodziny, był odznaczony Krzyżem Walecznych za swoją odwagę i poświęcenie w trakcie II wojny światowej. Jego niezłomna postawa podczas trudnych czasów wojennych zawsze stanowi dla nas wzór odwagi i determinacji. Opowieści o jego bohaterskich czynach stanowią niezatarte świadectwo jego patriotyzmu i oddania służbie dla ojczyzny."
    },
    {
        title:"Sergiusz Kowalski",
        imageUrl: "../../assets/images/face2.jpg",
        body:"Sergiusz, znany jako Segio, to niekwestionowany mistrz światowej sceny skateboardingowej. Jego miłość do jazdy na deskorolce nie tylko stała się jego pasją, ale także przekształciła się w imponujące osiągnięcia. Zdobywca mistrzostwa świata w skateboardingu, Segio wyczarowuje na desce unikalne triki, inspirując swoją kreatywnością kolejne pokolenia deskorolkarzy. Jego historia to nie tylko o triumfie nad grawitacją, ale także o determinacji, ciężkiej pracy i nieograniczonej miłości do sztuki jazdy na deskorolce."
    },
    {
        title:"Boguslaw Uniewski",
        imageUrl: "../../assets/images/face4.jpg",
        body:"Bogusław Uniewski, nieżyjący już wybitny wynalazca, pozostawił niezatarte piętno swoim genialnym umysłem. Jego opatentowane wynalazki stanowią dziedzictwo, które nadal kształtuje dzisiejszy świat.\nOd rewolucyjnych pomysłów w dziedzinie technologii po praktyczne rozwiązania codziennego życia, Uniewski, mimo że opuścił nasze grono, nadal żyje w pamięci tych, których życie poprawiły jego genialne koncepcje. Jego dziedzictwo ukazuje, że chociaż czas zabrał nam fizyczną obecność, to idee i innowacje są nieśmiertelne, trwając dalej jako inspiracja dla przyszłych pokoleń."
    },
]