import Hero from "./Hero/Hero";
import Skills from "./Skills";
import WorkExperience from "./WorkExperience/Workexperience";
import ContactMe from "./ContactMe/ContactMe";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MemoryPage from "./MemoryPage/MemoryPage";

const MainPage = () => {
    const { id } = useParams(); // Capture ID from the URL
    const [data, setData] = useState(null);

    useEffect(() => {
        // Set up the URL based on whether an ID is provided
        const url = id 
            ? `https://memorias.pl/fetchData.php?id=${id}` // Specific record if id exists
            : 'https://memorias.pl/fetchData.php'; // Fetch all records if no id

        console.log(url)

        fetch(url)
            .then(response => response.json())
            .then(data => setData(data))
            .catch(error => console.error('Error:', error));
    }, [id]);

    return (
     <div>
        {id && data ? (
            // Display Memory component if ID and data are available
            <MemoryPage 
                opis={data.opis} 
                imie={data.imie} 
                nazwisko={data.nazwisko} 
                img1={data.img1}
                img2={data.img2}
                img3={data.img3}
                
            />
        ) : (
            // Display other components if no specific ID and data are found
            <>
                <Hero />
                <Skills />
                <WorkExperience />
                <ContactMe />
            </>
        )}
    </div>
    );
};

export default MainPage;
