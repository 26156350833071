import React, { useState } from 'react';

const ImageSlider = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [startTouch, setStartTouch] = useState(0);

    // Handle swipe start
    const handleTouchStart = (e) => {
        setStartTouch(e.touches[0].clientX);
    };

    // Handle swipe end
    const handleTouchEnd = (e) => {
        const endTouch = e.changedTouches[0].clientX;
        if (startTouch - endTouch > 50) {
            goToNext();
        } else if (startTouch - endTouch < -50) {
            goToPrevious();
        }
    };

    // Function to go to the previous image
    const goToPrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    // Function to go to the next image
    const goToNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <div
            style={styles.sliderContainer}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
        >
            <div style={styles.imageContainer}>
                <img
                    src={images[currentIndex]}
                    alt={`Slide ${currentIndex}`}
                    style={styles.image}
                />
            </div>
            <div style={styles.dotContainer}>
                {images.map((_, index) => (
                    <span
                        key={index}
                        style={{
                            ...styles.dot,
                            opacity: currentIndex === index ? 1 : 0.5,
                        }}
                    ></span>
                ))}
            </div>
        </div>
    );
};

const styles = {
    sliderContainer: {
        position: 'relative',
        width: '80%',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    imageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    image: {
        width: '400px', // Set a fixed width for all images
        height: '300px', // Set a fixed height for all images
        objectFit: 'cover', // Ensures images are cropped proportionally to fill the area
        borderRadius: '10px',
    },
    dotContainer: {
        position: 'absolute',
        bottom: '10px',
        display: 'flex',
        gap: '5px',
    },
    dot: {
        height: '10px',
        width: '10px',
        backgroundColor: '#fff',
        borderRadius: '50%',
        transition: 'opacity 0.3s',
    },
};

export default ImageSlider;
