import "./ContactMe.css";
import ContactInfo from "./ContactInfoCard/ContactInfo";
import ContactForm from "./ContactForm/ContactForm";

const ContactMe = () => {
    return (
        <section id="contact-us-link" className="contact-container">
            <h5>Skontaktuj się z nami</h5>
            <div className="contactContent">
                <div style={{flex:1 }}>
                    <ContactInfo 
                        iconUrl="./assets/images/email.png"
                        text="stududu@gmail.com"
                    />
                    <ContactInfo 
                        iconUrl="./assets/images/tele.png"
                        text="0-700-99-3434r-42353"
                    />
                </div>
                <div style={{flex:1 }}>
                    <ContactForm />
                </div>
            </div>
        </section>
    )
}

export default ContactMe;